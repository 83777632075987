(function ($) {
    // Tooltip
    (function () {
        // Tooltip page video detail
        $('.video__meta [data-toggle="tooltip"]').tooltip();
        // Tooltip page channle detail
        $('.channel__follow [data-toggle="tooltip"]').tooltip();

        $('.showEmoji').tooltip();
    })();
    (function () {
        $('.comment__content .content-text').each(function( index ) {
            let el = $(this);
            if (el.prop('scrollHeight') > Math.round(el.height())){
                el.parent().removeClass('comment--less');
            }
        });
        $('.comment__viewmore label').click(function () {
            $this = $(this);
            $this.parents('.comment__viewmore').parent('.comment__content').toggleClass('comment--long');
        })
    })();
    (function () {
        if ( $( window ).width()<992){
            $('body').removeClass('menu-opened');
        } else {
            if(!$('body').hasClass('video__detail')){
                $('body').addClass('menu-opened');
            }
        }
        $( window ).resize(function() {
            if ( $( window ).width()<992){
                $('body').removeClass('menu-opened');
            } else {
                if(!$('body').hasClass('video__detail')){
                    $('body').addClass('menu-opened');
                }
            }
        });
        if ( $( window ).width()<800){
            $('.you-btn .icon').tooltip('dispose');
        } else {
            $('.you-btn .icon').tooltip();
        }
        $( window ).resize(function() {
            if ( $( window ).width()<800){
                $('.you-btn .icon').tooltip('dispose');
            } else {
                $('.you-btn .icon').tooltip();
            }
        });
    })();
    // Laydyload
    (function () {
        $('.lazy').each(function () {
            var src = $(this).attr('data-src');
            $(this).attr('src',src);

        })
    })();
    (function () {

    })();
    //hover box video
    fs.preview();

    // Repeater
    $('.repeater').repeater({
        show: function () {
            $(this).find('.namefile').empty();
            $(this).find('input[type=hidden]').val('');
            $(this).show();
        },
    });
    (function () {
        $(document).on('change','.file-sub',function (e) {
            if (e.target.files[0]){
                var fileName = e.target.files[0].name;
                console.log(fileName);
                $(this).next('span.namefile').html(fileName);
            }
            else {
                $(this).next('span.namefile').html('');
            }
        });
    })();
    //
    (function () {
        jQuery.validator.addMethod(
            "regex",
            function(value, element, regexp) {
                if (regexp.constructor != RegExp)
                    regexp = new RegExp(regexp);
                else if (regexp.global)
                    regexp.lastIndex = 0;
                return this.optional(element) || regexp.test(value);
            },"Error regular expression"
        );
        jQuery.validator.addMethod(
            "checkmail",
            function(value, element) {
                let res = value.split("@");
                res = res[1].split(".");
                if(res.length <=3){
                    return true;
                }else return false
            },"Error email format"
        );
    })();

    (function () {
        $('.form-search-close').on('click',function (e) {
            e.preventDefault();
            $('.header__search').hide();
        });
        $('.you-btn.search').on('click',function (e) {
            e.preventDefault();
            $('.header__search').show();
        });
    })();
})(jQuery);


// debounce
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}


