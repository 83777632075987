(function ($) {
    $(document).on('click','.category-pagination',function () {
        var $this = $(this);
        var id = $this.attr('data-id');
        var start = $this.attr('data-start');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/load-post-category',
            data:{
                id : id,
                start : start,
            },
            beforeSend:function(){
                $('.page-category .video-list .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function(res){
                console.log(res);
                if (res.success) {
                    setTimeout(function () {
                        $('.page-category .video-list .row .loading').remove();
                        var $scroll = true;
                        $('.page-category .video-list .row').append(res.success);
                        $this.attr('data-start',res.start);
                        fs.lazyload();
                        fs.preview();
                        $(window).bind('scroll', function() {
                            var $select = $('.page-category .video-list');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.category-pagination').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });
                        if (res.finish){
                            $this.remove();
                        }
                    },200);
                }
                else {
                    $('.page-category .video-list .row .loading').remove();
                    $this.remove();
                }
            }
        });
    });
    $(document).on('click','.live-category-pagination',function () {
        var $this = $(this);
        var id = $this.attr('data-id');
        var start = $this.attr('data-start');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/load-live-category',
            data:{
                id : id,
                start : start,
            },
            beforeSend:function(){
                $('.page-live-category .video-list .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function(res){
                console.log(res);
                if (res.success) {
                    setTimeout(function () {
                        $('.page-live-category .video-list .row .loading').remove();
                        $('.page-live-category .video-list .row').append(res.success);
                        $this.attr('data-start',res.start);
                        fs.lazyload();
                        fs.preview();
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.page-live-category .video-list');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.live-category-pagination').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });
                        if (res.finish){
                            $this.remove();
                        }

                    },200);
                }
                else {
                    $('.page-live-category .video-list .row .loading').remove();
                    $this.remove();
                }
            }
        });
    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.page-category .video-list');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.category-pagination').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.page-live-category .video-list');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.live-category-pagination').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
})(jQuery);
