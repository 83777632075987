(function ($) {
    // Submit comment
    (function () {
        $('.comment__form .form-comment').ajaxForm({
            success: function(res, statusText, xhr, $form) {
                $('.comment__form form .submit').removeClass('adding');
                if (res.success){
                    var $comment_count = parseInt($('.comment-count').text())+1;
                    $('.comment-count').html($comment_count);
                    $('.comment__list').prepend(res.success);
                    $('.comment__content .content-text').each(function( index ) {
                        let el = $(this);
                        if (el.prop('scrollHeight') > Math.round(el.height())){
                            el.parent().removeClass('comment--less');
                        }
                    });
                    $('.comment__viewmore label').click(function () {
                        $this = $(this);
                        $this.parents('.comment__viewmore').parent('.comment__content').toggleClass('comment--long');
                    })
                }
            },
            resetForm: true
        });
        $('.comment__form form .submit').on('click',function (e) {
            e.preventDefault();
            if (!$(this).hasClass('isLogin')) {
                $('.modal-require-login').modal('show');
                return;
            }
            if (!$('.form-comment #comment').val())
                return;
            if ($.trim($('.form-comment #comment').val()) == '')
                return;

            if (!$(this).hasClass('adding')) {
                $(this).addClass('adding');
                $(this).parents('.form-comment').submit();
                $('.comment__form form input[type="text"]').val('');
            }
        });
    })();
    //Submit form rep
    (function () {
        $(document).on('click','.form-reply .reply-submit',function (e) {
            e.preventDefault();
            var $this = $(this);
            var form = $this.parents('form');
            var reply = $this.parents('');
            if (!form.find('textarea').val())
                return;
            if ($.trim(form.find('textarea').val())=='')
                return;

            if (!$this.hasClass('adding')) {
                $this.addClass('adding');
                $('.form-reply form').ajaxForm({
                    success: function(res, statusText, xhr, $form) {
                        console.log(res);
                        $this.removeClass('adding');
                        if (res.success){
                            $('.reply-list .list[data-id="'+ res.id +'"]').prepend(res.success);
                            if ($('body').hasClass('page-manage-comment')){
                                $.notify({
                                    message: 'Đã gửi tin nhắn trả lời thành công'
                                },{
                                    type: 'meclip',
                                    delay: 3000,
                                    timer: 500,
                                    placement: {
                                        from: 'bottom',
                                        align: 'left'
                                    },
                                });
                            }
                        }else {
                            $.notify({
                                title: '<strong>Có lỗi!</strong>',
                                message: res.error
                            },{
                                type: 'danger',
                                delay: 3000,
                                timer: 500,
                            });
                        }
                        $form.find("input[type=text], textarea").val("");
                    }
                });
                form.submit();
            }

        });
    })();

    // Form
    (function () {
        var textarea = $('form .reply');

        textarea.on('keydown', autosize);

        function autosize(){
            var el = this;
            setTimeout(function(){
                el.style.cssText = 'height:auto;';
                // for box-sizing other than "content-box" use:
                // el.style.cssText = '-moz-box-sizing:content-box';
                el.style.cssText = 'height:' + el.scrollHeight + 'px';
            },0);
        }
    })();
    // Show form reply
    (function () {
        $(document).on('click','.comment__reply a.reply',function(e){
            e.preventDefault();
            if (!$(this).hasClass('isLogin')) {
                $('.modal-require-login').modal('show');
                return;
            }
            var id = $(this).attr('data-id');
            var postID = $(this).attr('data-postid');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/add-form-reply',
                data:{
                    id: id,
                    postid: postID
                },
                success:function(res){
                    if (res.data) {
                        if ($('.comment__item[data-id="'+id+'"] .comment__reply .reply-dialog').is(':empty')){
                            $('.comment__item[data-id="'+id+'"] .comment__reply .reply-dialog').html(res.data);
                        }
                    }
                    $('.comment__item[data-id="'+id+'"] .comment__reply .reply-dialog').show();
                    var textarea = $('form .reply');

                    textarea.on('keydown', autosize);

                    function autosize(){
                        var el = this;
                        setTimeout(function(){
                            el.style.cssText = 'height:auto;';
                            // for box-sizing other than "content-box" use:
                            // el.style.cssText = '-moz-box-sizing:content-box';
                            el.style.cssText = 'height:' + el.scrollHeight + 'px';
                        },0);
                    }
                }
            });

        })
    })();
    // Hide reply
    (function () {
        $(document).on('click','.form-reply .cancel',function (e) {
            $(this).parents('.reply-dialog').hide();
        })
    })();

    //load reply
    (function () {
        $(document).on('click','.reply-count',function () {
            var $this = $(this);
            var postId = $this.attr('data-postId');
            var parrentId = $this.attr('data-id');
            var start = $this.attr('data-start');
            var $total = $this.attr('data-total');
            if ($this.hasClass('load')){
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type:'POST',
                    url: BASE_URL + '/load-reply',
                    data:{
                        commentId : parrentId,
                        postId : postId,
                        start : start,
                        total : $total
                    },
                    success:function(res){
                        console.log(res);
                        if (res.success) {
                            $('.reply-list .list[data-id="'+ parrentId +'"]').empty().append(res.success).show();
                            $('.reply-hide[data-id="'+ parrentId +'"]').show();
                            $this.removeClass('load');
                            $this.hide();
                            if (parseInt($total)>10) {
                                $('.reply-view-more[data-id="'+ parrentId +'"]').attr('data-start',res.start);
                                if ($total > res.start){
                                    $('.reply-view-more[data-id="'+ parrentId +'"]').show();
                                }
                            }
                        }
                    }
                });
            } else {
                $('.reply-list .list[data-id="'+ parrentId +'"]').show();
                $('.reply-hide[data-id="'+ parrentId +'"]').show();
                $this.hide();
                if (parseInt($total)>10) {
                    $('.reply-view-more[data-id="'+ parrentId +'"]').show();
                }
            }

        });
        $(document).on('click','.reply-hide',function () {
            var $this = $(this);
            var postId = $this.attr('data-postId');
            var parrentId = $this.attr('data-id');
            $('.reply-list .list[data-id="'+ parrentId +'"]').hide();
            $('.reply-view-more[data-id="'+ parrentId +'"]').hide();
            $('.reply-count[data-id="'+ parrentId +'"]').show();
            $this.hide();
        });
        $(document).on('click','.reply-view-more',function () {
            var $this = $(this);
            var postId = $this.attr('data-postId');
            var parrentId = $this.attr('data-id');
            var start = $this.attr('data-start');
            var $total = $this.attr('data-total');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/load-reply',
                data:{
                    commentId : parrentId,
                    postId : postId,
                    start : start,
                    total : $total
                },
                success:function(res){
                    if (res.success) {
                        $('.reply-list .list[data-id="'+ parrentId +'"]').append(res.success).show();
                        $('.reply-view-more[data-id="'+ parrentId +'"]').attr('data-start',res.start);
                        if (res.start > $total){
                            $('.reply-view-more[data-id="'+ parrentId +'"]').hide();
                        }
                    }
                }
            });
        });
    })();

    //Load more comment
    (function () {
        $(document).on('click','.load-more-comment',function(){
            var $this = $(this);
            var postId = $this.attr('data-postId');
            var start = $this.attr('data-start');
            var total = $this.attr('data-total');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/load-comment',
                data:{
                    postId : postId,
                    start : start,
                    total : total
                },
                beforeSend:function(){
                    $('.comment__list').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    if (res.success) {
                        setTimeout(function () {
                            var $select = $('.comment__list');
                            $('.comment__list .loading').remove();
                            $select.append(res.success);
                            if (parseInt(total) > (parseInt(res.start)+10)){
                                $this.attr('data-start',res.start)
                            }else {
                                $this.remove();
                            }
                            var $scroll = true;
                            if (!$select.length)
                                return;
                            $(window).bind('scroll', function() {
                                if ($scroll){
                                    if(Math.round($(window).scrollTop()+10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                        $('.load-more-comment').trigger('click');
                                        $scroll = false;
                                    }
                                    else {
                                        $scroll = true;
                                    }
                                }
                            });
                        },200)
                    }
                    else {
                        $('.comment__list .loading').remove();
                    }
                }
            });
        })
    })();
    // scroll load comment
    (function () {
        var $scroll = true;
        var $select = $('.comment__list');
        if (!$select.length)
            return;
        $(window).bind('scroll', function() {
            if ($scroll){
                if(Math.round($(window).scrollTop()+10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.load-more-comment').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
})(jQuery);
