var jwDefaults = {
    "abouttext": "MeClip",
    "aboutlink": BASE_URL,
    "aspectratio": "16:9",
    "pid": "glc5eiuf",
    "key": jwplayerKey,
    "autostart":true,
    "mute": false,
    "volume": 70,
    "playbackRateControls": true,
    "controls": true,
    "displaydescription": false,
    "repeat": false,
    "stagevideo": false,
    "stretching": "uniform",
    "flashplayer": BASE_URL+"/jwplayer.flash.swf",
    "floating" :{
        "dismissible": true
    },
    "width": "100%",
    "skin" : {
        "name": "yc-jwplayer"
    },
    "preload": "none",
    "intl":{
        vi:{
            "advertising": {
                "admessage": "Quảng cáo này sẽ kết thúc sau xx",
                "cuetext": "Quảng cáo",
                "displayHeading": "Quảng cáo",
                "loadingAd": "Đang tải quảng cáo",
                "podmessage": "Quảng cáo thứ __AD_POD_CURRENT__ trên __AD_POD_LENGTH__.",
                "skipmessage": "Bỏ qua quảng cáo sau xx",
                "skiptext": "Bỏ qua"
            },
            "airplay": "AirPlay",
            "audioTracks": "Các track âm thanh",
            "auto": "Tự động",
            "buffer": "Đang tải",
            "cast": "Chromecast",
            "cc": "Các tiêu đề đã đóng",
            "close": "Đóng",
            "errors": {
                "badConnection": "Video này không phát được vì kết nối internet của bạn đang có vấn đề.",
                "cantLoadPlayer": "Rất tiếc, trình phát video này không tải được.",
                "cantPlayInBrowser": "Video này không phát được ở trình duyệt này.",
                "cantPlayVideo": "File video này không phát được.",
                "errorCode": "Mã lỗi",
                "liveStreamDown": "Nội dung phát trực tiếp này gặp trục trặc hoặc đã kết thúc.",
                "protectedContent": "Có vấn đề khi truy cập nội dung được bảo vệ.",
                "technicalError": "Video này không phát được vì lỗi kỹ thuật."
            },
            "exitFullscreen": "Thoát khỏi chế độ \"\"Toàn màn hình\"\"",
            "fullscreen": "Toàn màn hình",
            "hd": "Chất lượng",
            "liveBroadcast": "Trực tiếp",
            "logo": "Logo",
            "mute": "Tắt tiếng",
            "next": "Kế tiếp",
            "nextUp": "Tiếp theo",
            "notLive": "Không trực tiếp",
            "off": "Tắt",
            "pause": "Tạm ngừng",
            "play": "Phát",
            "playback": "Phát",
            "playbackRates": "Tốc độ chạy",
            "player": "Trình phát video",
            "poweredBy": "Cung cấp bởi",
            "prev": "Trước",
            "related": {
                "autoplaymessage": "Nội dung tiếp theo sẽ được phát sau xx",
                "heading": "Thêm nhiều video nữa"
            },
            "replay": "Phát lại",
            "rewind": "Tua lại 10 giây",
            "settings": "Cài đặt",
            "sharing": {
                "copied": "Đã được sao chép",
                "email": "Email",
                "embed": "Nhúng",
                "heading": "Chia sẻ",
                "link": "Liên kết"
            },
            "slider": "Tìm kiếm",
            "stop": "Dừng",
            "unmute": "Bật tiếng",
            "videoInfo": "Thông tin về video này",
            "volume": "Âm lượng",
            "volumeSlider": "Âm lượng",
            "shortcuts": {
                "playPause": "Phát/Tạm ngừng",
                "volumeToggle": "Tắt tiếng/Bật tiếng",
                "fullscreenToggle": "Toàn màn hình/Thoát khỏi chế độ \"\"Toàn màn hình\"\"",
                "seekPercent": "Tua %",
                "keyboardShortcuts": "Phím Tắt",
                "increaseVolume": "Tăng âm",
                "decreaseVolume": "Giảm âm",
                "seekForward": "Tua Tới",
                "seekBackward": "Tua Lùi",
                "spacebar": "Phím Cách",
                "captionsToggle": "Phụ đề Mở/Tắt"
            }
        }

    }
};
jwplayer.defaults = jwDefaults;


