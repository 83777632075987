(function ($) {
    $('.watchTogetherFinish').on('click',function (e) {
        e.preventDefault();
        var $this = $(this);
        $('.modal-watch-togother-finish').modal('show');
    });
    $('.watch-together-start').on('click',function (e) {
        e.preventDefault();
        var $this = $(this);
        var id = $this.attr('data-id');
        if (!$this.hasClass('isLogin')){
            $('.modal-require-login').modal('show');
            $('.video__meta [data-toggle="tooltip"]').tooltip();
            return;
        }else{
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                dataType : 'json',
                url: BASE_URL + '/watch-together-info',
                data:{

                },
                success:function(res){
                    console.log(res);
                    if (res.data){
                        if (res.data.isBoss){
                            $('a.watch-together-old').attr('href',BASE_URL + '/watch-together/'+res.data.roomId)
                            $('.modal-watch-togother').modal('show');
                        }else{
                            window.location.href = BASE_URL+'/watch-together-create/'+id;
                        }
                    }else{
                        window.location.href = BASE_URL+'/watch-together-create/'+id;
                    }

                }
            })
        }
    });
    $('.showEmoji').hover(function () {
        $('.emojiIcon').addClass('show')
    },function () {

    })
    $('.emojiIcon').hover(function () {

    },function () {
        $('.emojiIcon').removeClass('show')
    })
})(jQuery);
