(function ($) {
    $('.channel-hashtag').tagsinput({

        confirmKeys: [32, 13, 44]
    });
    $('.video-hashtag').tagsinput({
        confirmKeys: [32, 13, 44]
    });
    $('input').on('itemAddedOnInit', function(event) {
        // event.item: contains the item
    });
    $(document).on('input', '.bootstrap-tagsinput input[type="text"]', function () {
        var txtEntered = false;
        if ($(this).val() !== "") {
            txtEntered = true;
        }
        // Check chuỗi rỗng
        if (txtEntered)
        {
            var string = $(this).val();
            // Loại bỏ dấu trong chuỗi
            var strAccents = string.split('');
            var strAccentsOut = new Array();
            var strAccentsLen = strAccents.length;
            var accents =    "ÀÁẢÃẠÂẦẤẨẪẬÄÅàáảãạâầấẩẫậäåÒÓỎÕỌÔỒỐỔỖỘÖØòóỏõọôồốổỗộöøÈÉẺẼẸÊỀẾỂỄỆËèéẻẽẹêềếểễệëðÇçÐÌÍỈĨỊÎÏìíỉĩịîïÙÚỦŨỤƯỪỨỬỮỰÛÜùúủũụưừứửữựûũÑñŠšŸÿýŽž";
            var accentsOut = "AAAAAAAAAAAAAaaaaaaaaaaaaaOOOOOOOOOOOOOoooooooooooooEEEEEEEEEEEEeeeeeeeeeeeeeCcDIIIIIIIiiiiiiiUUUUUUUUUUUUUuuuuuuuuuuuuuNnSsYyyZz";
            for (var y = 0; y < strAccentsLen; y++) {
                if (accents.indexOf(strAccents[y]) != -1) {
                    strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
                } else
                    strAccentsOut[y] = strAccents[y];
            }
            strAccentsOut = strAccentsOut.join('');
            //Loại bỏ khoảng trống
            var temp = "";
            splitstring = strAccentsOut.split(" ");
            for(i = 0; i < splitstring.length; i++)
                temp += splitstring[i];

            $(this).val(temp);
        }
    });
})(jQuery);
