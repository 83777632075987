(function ($) {
    // Form sugn up cooperation

    $.datetimepicker.setLocale('vi');
    $('.cooperation__form input[name=date]').datetimepicker({
        timepicker:false,
        format:'d/m/Y',
        maxDate: '0',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });

    $('.cooperation__form').ajaxForm({
        success: function(res, statusText, xhr, $form) {
            console.log(res);
            if (res.success){
                $.notify({
                    message: res.success
                },{
                    type: 'meclip',
                    delay: 3000,
                    timer: 500,
                    placement: {
                        from: 'bottom',
                        align: 'right'
                    },
                });
                $('.cooperation__form input[name="name"]').val('');
                $('.cooperation__form input[name="phone"]').val('');
                $('.cooperation__form input[name="address"]').val('');
                $('.cooperation__form input[name="passport"]').val('');
                $('.cooperation__form input[name="where"]').val('');
                $('.cooperation__form input[name="date"]').val('');
                $('.cooperation__form input[name="tax_code"]').val('');
            }else{
                $.notify({
                    message: res.error
                },{
                    type: 'meclip',
                    delay: 3000,
                    timer: 500,
                    placement: {
                        from: 'bottom',
                        align: 'right'
                    },
                });
            }
        }
    });

    // dislike video
    $(document).on('click','.sign_up-cooperation-submit',function (e) {
        e.preventDefault();
        if($('.cooperation__form').valid() == true) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'GET',
                url: BASE_URL + '/checkCooperation',
                data:{},
                success:function(data){
                    if (data.success){
                        $('.cooperation__form').submit();
                    }else{
                        $.notify({
                            message: data.error
                        },{
                            type: 'meclip',
                            delay: 2000,
                            timer: 100,
                            placement: {
                                from: 'bottom',
                                align: 'left'
                            },
                        });
                    }
                }
            });
        }else{
            $.notify({
                message: 'Hãy điền đầy đủ thông tin cần thiết.'
            },{
                type: 'meclip',
                delay: 2000,
                timer: 100,
                placement: {
                    from: 'bottom',
                    align: 'left'
                },
            });
        }
    });

})(jQuery);
