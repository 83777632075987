(function ($) {
    //Follow
    $(document).on('click','.btn-follow-channel',function (e) {
        e.preventDefault();
        var $this = $(this);
        if (!$this.hasClass('isLogin')) {
            $('.modal-require-login').modal('show');
            return;
        }
        var id = $(this).attr('data-id');
        var channelName = $(this).attr('data-name');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/channel-subscribe',
            data:{
                id: id,
                channelName: channelName
            },
            success:function(res){
                console.log(res);
                if (res.success){
                    $('.channel-follow').html(res.data);
                    $.notify({
                        message: res.success
                    },{
                        type: 'meclip',
                        delay: 3000,
                        timer: 500,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
                else {
                    $.notify({
                        message: res.error
                    },{
                        type: 'meclip',
                        delay: 3000,
                        timer: 500,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
            }
        });
    });
    $(document).on('click','.btn-unfollow',function (e) {
        e.preventDefault();
        var id = $(this).attr('data-id');

        $('.modal-unfollow[data-id='+ id +']').modal('show');
    });
    $(document).on('click','.btn-unfollow-channel',function (e) {
        e.preventDefault();
        var $this = $(this);
        var id = $this.attr('data-id');
        var channelName = $this.attr('data-name');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/channel-unsubscribe',
            data:{
                id: id,
                channelName: channelName
            },
            success:function(res){
                console.log(res);
                if (res.success){
                    $this.parents('.channel-follow').html(res.data);
                    $.notify({
                        message: res.success
                    },{
                        type: 'meclip',
                        delay: 3000,
                        timer: 500,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
                else {
                    $.notify({
                        message: res.error
                    },{
                        type: 'meclip',
                        delay: 3000,
                        timer: 500,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
                $('.modal-unfollow').modal('hide');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
            }
        });
    });

    // Add relate channel
    (function () {

        var channel_relate = fs.channelRelate('.swiper-channel-relate');
        $(document).on('click','.channel-add-relate',function (e) {
            e.preventDefault();
            $('.channel-relate').find('.mes-err').html('');
            $('.channel-relate').find("input[type=text], textarea").val("");
            $('.modal-add-channel').modal('show');
        });
        $('.channel-relate').ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]

                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {
                $form.find('.mes-err').html('');
                if(res.success){
                    channel_relate.prependSlide(res.success);
                    $form.find("input[type=text], textarea").val("");
                    $form.append('<input type="hidden" data-id="'+ res.id +'" name="channelsRelate[]" value="'+ res.id +'">');
                    $('.channel__create').append('<input type="hidden" data-id="'+ res.id +'" name="channelsRelate[]" value="'+ res.id +'">');
                    $('.modal-add-channel').modal('toggle');
                    $('body').removeClass('modal-open');
                    $('.modal-backdrop').remove();
                    $('form.channel__edit').addClass('changed');
                    fs.channelRelate('.swiper-channel-relate');
                }else {
                    $form.find('.mes-err').html('<div class="form-group">'+ res.error +'</div>');
                }
            }
        });

        $(document).on('click','.box-channel .remove-channel',function (e) {
            e.preventDefault();
            var index = $('.box-channel .remove-channel').index(this);
            var id = $(this).attr('data-id');
            $('.channel-relate input[data-id='+ id +']').remove();
            $('.channel__create input[data-id='+ id +']').remove();
            channel_relate.removeSlide(index);
        })
    })();
    // Update channel
    (function () {
        $('form.channel__edit :input,form.channel__edit textarea').on('change',function () {
            var form = $(this).closest('form').addClass('changed');

        });

        $(document).on('click','.btn-channel-update',function (e) {
            e.preventDefault();
            var form = $(this).parents('form');
            if (form.hasClass('changed')){
                form.submit();
            }
        });

        $('.channel__edit').ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]

                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $form.removeClass('changed');
                $.notify({
                    message: res
                },{
                    type: 'meclip',
                    delay: 2500,
                    timer: 200,
                    placement: {
                        from: 'bottom',
                        align: 'left'
                    },
                });
            }
        })

    })();
    //Load video follow
    (function () {
        $(document).on('click','.channel-follow-videos .load-more',function (e) {
            e.preventDefault();
            var $this = $(this);
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/follow-videos',
                data:{
                    start : start,
                },
                beforeSend:function(){
                    $('.channel-follow-videos .video .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    console.log(res);
                    if (res.success) {

                        setTimeout(function () {
                            $('.channel-follow-videos .video .row .loading').remove();
                            $('.channel-follow-videos .video .row').append(res.success);
                            fs.lazyload();
                            if (res.finish){
                                $this.remove();
                            }
                            $this.attr('data-start',res.start);
                            var $scroll = true;
                            $(window).bind('scroll', function() {
                                var $select = $('.channel-follow-videos .list-video');
                                if (!$select.length)
                                    return;
                                if ($scroll){
                                    if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                        $('.channel-follow-videos .load-more').trigger('click');
                                        $scroll = false;
                                    }
                                    else {
                                        $scroll = true;
                                    }
                                }
                            });
                        },200);
                    }
                    else {
                        $('.channel-follow-videos .video .row .loading').remove();
                        if (res.finish){
                            $this.remove();
                        }
                    }
                }
            });
        })
    })();
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.channel-follow-videos .list-video');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.channel-follow-videos .load-more').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    // load video of channel
    $(document).on('click','.channel-videos .loadmore',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');
        var id = $this.attr('data-id');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/channel/'+ id +'/videos',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.channel-videos .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.channel-videos .loading').remove();
                        $('.channel-videos .row').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.channel-videos');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.channel-videos .loadmore').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });
                        fs.lazyload();
                        fs.preview();
                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.channel-videos .loading').remove();
                    $this.remove();
                }
            }
        });


    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.channel-videos');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.channel-videos button.loadmore').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    // message
    $(document).on('click','.channel__mes a',function(e){
        e.preventDefault;
        var $this = $(this);
        $('.channel__info [data-toggle="tooltip"]').tooltip('dispose');
        if(!$this.hasClass('isLogin')){
            $('.modal-require-login').modal('show');
            $('.channel__info [data-toggle="tooltip"]').tooltip();
        }else {
            $('.modal-send-mes').modal('show');
            $('.channel__info [data-toggle="tooltip"]').tooltip();
        }
    });
    // Đăng ký nhận thông báo
    $(document).on('click','.channel__notifications a',function(e){
        e.preventDefault;
        var $this = $(this);
        $('.channel__info [data-toggle="tooltip"]').tooltip('dispose');
        if(!$this.hasClass('isLogin')){
            $('.modal-require-login').modal('show');
            $('.channel__info [data-toggle="tooltip"]').tooltip();
        }else {
            $('.channel__info [data-toggle="tooltip"]').tooltip();
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/channel/'+ $this.attr('data-id') +'/notified',{
                type: 'POST',
                dataType: 'json',
                data: {

                },
                success:function (res){
                    console.log(res);
                    if(res.code == 200){
                        if (res.data.notified){
                            $this.addClass('notified');
                            $.notify({
                                message: 'Đã đăng ký nhận thông báo'
                            },{
                                type: 'meclip',
                                delay: 2500,
                                timer: 100,
                                placement: {
                                    from: 'bottom',
                                    align: 'left'
                                },
                            });
                        }else {
                            $this.removeClass('notified');
                            $.notify({
                                message: 'Đã hủy nhận thông báo'
                            },{
                                type: 'meclip',
                                delay: 2500,
                                timer: 100,
                                placement: {
                                    from: 'bottom',
                                    align: 'left'
                                },
                            });
                        }
                    }
                    else{
                        $.notify({
                            message: res.msg
                        },{
                            type: 'meclip',
                            delay: 2500,
                            timer: 100,
                            placement: {
                                from: 'bottom',
                                align: 'left'
                            },
                        });
                    }
                }
            });
        }
    });
    $('form.send-msg').ajaxForm({
        beforeSubmit: function(arr, $form, options) {
            // The array of form data takes the following form:
            // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
            // return false to cancel submit
        },
        success: function(res, statusText, xhr, $form) {
            $('.modal-send-mes').modal('hide');

            if (res.success){
                $.notify({
                    message: res.success
                },{
                    type: 'meclip',
                    delay: 2500,
                    timer: 100,
                    placement: {
                        from: 'bottom',
                        align: 'left'
                    },
                });
            }else {
                $.notify({
                    message: res.error
                },{
                    type: 'meclip',
                    delay: 2500,
                    timer: 100,
                    placement: {
                        from: 'bottom',
                        align: 'left'
                    },
                });
            }
        },
        resetForm: true
    });
    // Load more pll
    $(document).on('click','section.playlist .loadmore',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');
        var id = $this.attr('data-id');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/channel/'+ id +'/playlist',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.channel-playlist .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.channel-playlist .loading').remove();
                        $('.channel-playlist .row').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.channel-playlist');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.channel-playlist button.loadmore').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });

                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manager-video .loading').remove();
                    $this.remove();
                }


            }
        });


    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.channel-playlist');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.channel-playlist button.loadmore').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
})(jQuery);
