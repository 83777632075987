(function ($) {
    // (function () {
    //     $('.login__form .btn-login').click(function (e) {
    //         e.preventDefault();
    //
    //         $('.login__form .user-mess').empty();
    //         $('.login__form .general-mess').empty();
    //         $.ajaxSetup({
    //             headers: {
    //                 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //             }
    //         });
    //         $.ajax(BASE_URL + '/login',{
    //             type: 'POST',
    //             dataType: 'json',
    //             data: {
    //                 username: $('#username').val(),
    //                 urlRedirect: $('input[name="redirect"]').val(),
    //             },
    //             beforeSend:function(){
    //                 // $('section.login').addClass('loging');
    //             },
    //             success:function (data){
    //                 console.log(data);
    //                 if (data.error){
    //                     var err_user_mes = data.error['username'] ? data.error['username'] : '';
    //                     var err_mes_pass = data.error['password'] ? data.error['password'] : '';
    //                     var err_general_mes = data.error['general'] ? data.error['general'] : '';
    //                     $('.login__form .user-mess').append(err_user_mes);
    //                     $('.login__form .pass-mess').append(err_mes_pass);
    //                     $('.login__form .general-mess').append(err_general_mes);
    //                     // setTimeout(function () {
    //                     //     $('section.login').removeClass('loging');
    //                     // },500);
    //                 }
    //                 else if(data.success) {
    //                     $('#confirmOtp').modal('show');
    //                     // $('section.login .overlay').html(data.success);
    //                     // setTimeout(function () {
    //                     //     $(location).attr('href', data.url)
    //                     // },500);
    //                 }
    //             }
    //         });
    //     })
    // })();
    $('.confirmOtp').on('click',function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/confirm-otp',{
            type: 'POST',
            dataType: 'json',
            data: {
                username: $('#username').val(),
                otp : $('#otp').val(),
                redirect: $('input[name="redirect"]').val(),
            },
            beforeSend:function(){
                $('#confirmOtp .otp-mess').empty();
                $('section.login').addClass('loging');
            },
            success:function (data){
                console.log(data);
                if (data.error){
                    var err = data.error ? data.error : '';
                    $('#confirmOtp .otp-mess').append(err);
                    setTimeout(function () {
                        $('section.login').removeClass('loging');
                    },500);
                }else {
                    $('#confirmOtp').modal('hide');
                    $('.form__register .general-mess').append('Đăng nhập thành công');
                    setTimeout(function () {
                        $(location).attr('href', data.url)
                    },500);
                }
            }
        })
    });
    (function () {
        $('.pwd a').click(function (e) {
            e.preventDefault();
            if($('#password').attr('type') == 'text'){
                $('#password').attr('type','password')
            }else{
                $('#password').attr('type','text')
            }
        })
    })();
    (function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('.form__register .sendOtp').click(function (e) {
            e.preventDefault();
            $.ajax(BASE_URL + '/send-otp',{
                type: 'POST',
                dataType: 'json',
                data: {
                    username: $('#username').val(),
                },
                beforeSend:function(){
                    $('.form__register .general-mess').empty()
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        var err = data.error ? data.error : '';
                        $('.form__register .general-mess').append(err);
                    }else {
                        if (data.success.code != 200){
                            $('.form__register .general-mess').append(data.success.msg);
                        }
                    }
                }
            })
        })
    })();
    (function () {
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('.btn-register').click(function (e) {
            e.preventDefault();
            $.ajax(BASE_URL + '/register',{
                type: 'POST',
                dataType: 'json',
                data: {
                    username: $('#username').val(),
                    password: $('#password').val(),
                    otp :$('#otp').val(),
                },
                beforeSend:function(){
                    $('.form__register .general-mess').empty()
                },
                success:function (data){
                    console.log(data);
                    if (data.error){
                        var err_user_mes = data.error.username ? data.error.username : '';
                        var err_mes_pass = data.error.password ? data.error.password : '';
                        var err_mes_otp = data.error.otp ? data.error.otp : '';
                        var err_general_mes = data.error.general ? data.error.general : '';

                        $('.form__register .user-mess').append(err_user_mes);
                        $('.form__register .pass-mess').append(err_mes_pass);
                        $('.form__register .otp-mess').append(err_mes_pass);
                        $('.form__register .general-mess').append(err_general_mes);
                    }else {
                        location.reload()
                    }
                }
            })
        })
    })();
})(jQuery);
