(function ($) {
    //Load video follow
    (function () {
        $(document).on('click','.favorite .favorite-pagination',function (e) {
            e.preventDefault();
            var $this = $(this);
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/favorite-videos',
                data:{
                    start : start,
                },
                beforeSend:function(){
                    $('.page-favorite .video > .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    console.log(res);
                    if (res.success) {

                        setTimeout(function () {
                            $('.page-favorite .video > .row .loading').remove();
                            $('.page-favorite .video > .row').append(res.success);
                            fs.lazyload();
                            if (res.finish){
                                $this.remove();
                            }
                            $this.attr('data-start',res.start);
                            var $scroll = true;
                            $(window).bind('scroll', function() {
                                var $select = $('.page-favorite .list-video');

                                if (!$select.length)
                                    return;
                                if ($scroll){

                                    if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                        $('.favorite .favorite-pagination').trigger('click');
                                        $scroll = false;
                                    }
                                    else {
                                        $scroll = true;
                                        console.log(2);
                                    }
                                }
                            });
                        },200);
                    }
                    else {
                        $('.page-favorite .video .row .loading').remove();
                        if (res.finish){
                            $this.remove();
                        }
                    }
                }
            });
        })
    })();
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.page-favorite .list-video');

            if (!$select.length)
                return;
            if ($scroll){

                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.favorite .favorite-pagination').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                    console.log(2);
                }
            }
        });
    })();
})(jQuery);
