/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */


require('./bootstrap');
// require('./vendor/jwplayer.core.controls.html5');

require('./vendor/jwplayer');
// require('./vendor/jwpsrv');
// require('./vendor/dai');
// require('./vendor/freewheel');
// require('./vendor/gapro');
// require('./vendor/provider.hlsjs');
require('./login');
require('./upload');
require('./personal');
require('./navbar');
require('./modal');
require('./dropify');
require('./input-tag');
require('./video');
require('./validate');
require('./notification');
require('./page-static');
require('./custom');
require('./jwplayer');
require('./image-picker');
require('./comment');
require('./channel');
require('./category');
require('./manager-content');
require('./manager');
require('./swiper');
require('./playlist');
require('./history');
require('./favorite');
require('./livestream');
require('./search');
require('./watchTogether');
require('./cooperation');

// require('./firebase');


