(function($){
   (function () {
      if ($('.channel-interactive').length ){
          $.ajaxSetup({
              headers: {
                  'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
              }
          });
          $.ajax({
              type:'POST',
              url: BASE_URL + '/management/load-channel',
              data:{

              },
              beforeSend:function(){

              },
              success:function(res){
                  console.log(res);
                  if (res.html){
                      $('.channel-interactive').append(res.html);
                      fs.lazyload();
                      fs.preview();
                  }
              }
          });
      }
   })();
   $(document).on('click','.reply-sender',function(e){
       e.preventDefault();
       var $this = $(this);
       var id = $this.attr('data-id');
       $this.parents('.list-group-item').find('.sender-detail').slideToggle(200);

       if($this.parents('.list-group-item').hasClass('unread')){
           $.ajaxSetup({
               headers: {
                   'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
               }
           });
           $.ajax({
               type:'POST',
               url: BASE_URL + '/management/read-message',
               data:{
                   id : id
               },
               beforeSend:function(){

               },
               success:function(res){

                   if (res.code == 200){
                       $this.parents('.list-group-item').removeClass('unread');
                       $this.parents('.list-group-item').addClass('read');
                   }
               }
           });
       }

   });
   $(document).on('click','.sender-reply-cancel',function(e){
        e.preventDefault();
        $(this).parents('.list-group-item').find('.sender-detail').slideUp(200);
    });


   $(document).on('submit','.reply-message',function (e){
       e.preventDefault();
       var $this = $(this);
       $this.ajaxSubmit({
           beforeSubmit: function(formData, jqForm, options){
               var text = jqForm.find('[name=message]').val();
               if(text == ''){
                   $.notify({
                       message: 'Bạn chưa nhập nội dung tin nhắn.'
                   },{
                       type: 'meclip',
                       delay: 3000,
                       timer: 500,
                       placement: {
                           from: 'bottom',
                           align: 'left'
                       },
                   });
                   return false;
               }
           },
           success: function(res, statusText, xhr, $form){
               console.log(res);

               if (res.code==200){
                   var html = '';
                   var avartar = res.data.senderAvatar ? res.data.senderAvatar : BASE_URL + '/images/default-user.png';
                   html += '<div class="sender">\n' +
                       '<img class="sender-avatar" src="'+avartar+'" alt="avatar">\n' +
                       '<h6 class="mb-0 sender-name">'+res.data.senderName+' :</h6>\n' +
                       '<span class="sender-content">'+res.data.content+'</span>\n' +
                       '</div>';
                   $form.find('.list-reply').append(html);
                   $form.find('.list-reply').animate({
                       scrollTop: $form.find('.list-reply')[0].scrollHeight}, 0);
                   $.notify({
                       message: 'Tin nhắn đã được gửi'
                   },{
                       type: 'meclip',
                       delay: 3000,
                       timer: 500,
                       placement: {
                           from: 'bottom',
                           align: 'left'
                       },
                   });
               }else{
                   $.notify({
                       message: res.msg
                   },{
                       type: 'meclip',
                       delay: 3000,
                       timer: 500,
                       placement: {
                           from: 'bottom',
                           align: 'left'
                       },
                   });
               }
           },
           resetForm: true
       });
   });
    $(document).on('click','.manager__message .load-more',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/management/load-more-mesage',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.manager__message .list-message-new').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.manager__message .loading').remove();
                        $('.manager__message .list-message-new').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.list-message');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.list-message button.load-more').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });

                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manager__message .loading').remove();
                    $this.remove();
                }
            }
        });
    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.list-message');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.list-message button.load-more').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    // Load tin nhắn trả lời
    $(document).on('click','.manager__message .load-more-reply',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/management/load-more-mesage-reply',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.manager__message .list-message-reply').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.manager__message .loading').remove();
                        $('.manager__message .list-message-reply').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).on('scroll', function() {
                            var $select = $('.list-message-reply');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    console.log(Math.round($(window).scrollTop() + 10));
                                    $('.list-message-reply button.load-more-reply').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });

                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manager__message .loading').remove();
                    $this.remove();
                }
            }
        });
    });
    (function () {
        var $scroll = true;
        $(window).on('scroll', function() {
            var $select = $('.list-message-reply');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    console.log(Math.round($(window).scrollTop() + 10));
                    $('.list-message-reply button.load-more-reply').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    (function () {
        $.datetimepicker.setLocale('vi');
        $('.dateStart,.dateEnd').datetimepicker({
            timepicker:false,
            format:'d/m/Y',
            maxDate: '0',
            i18n:{
                vi:{
                    months:[
                        'Th1','Th2','Th3','Th4',
                        'Th5','Th6','Th7','Th8',
                        'Th9','Th10','Th11','Th12'
                    ],
                    dayOfWeek:[
                        "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                        "Thứ 5", "Thứ 6", "Thứ 7",
                    ]
                }
            }
        });
    })();
    // load video
    // Load tin nhắn trả lời
    $(document).on('click','.manage-video .loadmore',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/management/load-more-video',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.manage-video .list-video').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.manage-video .list-video .loading').remove();
                        $('.manage-video .list-video').append(res.success);
                        $this.attr('data-start',res.start);
                        fs.preview();
                        fs.lazyload();
                        var $scroll = true;
                        $(window).on('scroll', function() {
                            var $select = $('.manage-video .list-video');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    console.log(Math.round($(window).scrollTop() + 10));
                                    $('.manage-video button.loadmore').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });

                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manage-video .list-video .loading').remove();
                    $this.remove();
                }
            }
        });
    });
    (function () {
        var $scroll = true;
        $(window).on('scroll', function() {
            var $select = $('.manage-video .list-video');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    console.log(Math.round($(window).scrollTop() + 10));
                    $('.manage-video button.loadmore').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    // Load comment
    $(document).on('click','.manager__comment .load-more',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/management/load-more-comment',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.manage-video .list-comment-new').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.manage-video .loading').remove();
                        $('.manage-video .list-comment-new').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.list-comment');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.list-comment button.load-more').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });

                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manager__comment .loading').remove();
                    $this.remove();
                }
            }
        });
    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.list-comment');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.list-comment button.load-more').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
})(jQuery);
