(function ($) {
    $('.header__navbar .overlay').click(function () {
        $('body').removeClass('menu-opened');
    });
    $('.btn-user .avatar-button').click(function (e) {
        e.preventDefault();
        $('body').toggleClass('menu-user');
        $('.btn-user .user-info').toggleClass('menu-open');
    });
    $('.menu-button').on('click',function () {
        $('body').toggleClass('menu-opened');
    });

})(jQuery);
