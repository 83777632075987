(function ($) {
    //modal reg package
    $(document).on('click','.video-require',function(){
        $('.modal-reg-package').modal('show');
    });
    // Like video
    $(document).on('click','.video__rate .like',function (e) {
        e.preventDefault();
        var $this = $(this);
        $('.video__meta [data-toggle="tooltip"]').tooltip('dispose');
        if (!$this.hasClass('isLogin')) {
            $('.modal-require-login').modal('show');
            $('.video__meta [data-toggle="tooltip"]').tooltip();
            return;
        }
        var id = $this.attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/video-like',
            data:{
                id: id
            },
            success:function(data){
                if (data.success){
                    if (!$this.hasClass('liked')) {
                        $.notify({
                            message: 'Đã thêm video vào thích'
                        },{
                            type: 'meclip',
                            delay: 2500,
                            timer: 200,
                            placement: {
                                from: 'bottom',
                                align: 'left'
                            },
                        });
                    }
                    $('.video__rate').html(data.success);
                    $('.video__meta [data-toggle="tooltip"]').tooltip();
                }
            }
        });
    });

    // dislike video
    $(document).on('click','.video__rate .dislike',function (e) {
        e.preventDefault();
        var $this = $(this);
        $('.video__meta [data-toggle="tooltip"]').tooltip('dispose');
        if (!$this.hasClass('isLogin')) {
            $('.modal-require-login').modal('show');
            $('.video__meta [data-toggle="tooltip"]').tooltip();
            return;
        }
        var id = $this.attr('data-id');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax({
            type:'POST',
            url: BASE_URL + '/video-dislike',
            data:{
                id: id
            },
            success:function(data){
                if (data.success){
                    $this.toggleClass('disliked');
                    $('.video__rate').html(data.success);
                    $('.video__meta [data-toggle="tooltip"]').tooltip();
                }
            }
        });
    });

    // share
    (function () {

    })();
    (function () {
        $(document).on('click','.copy-clipboard',function (e) {
            e.preventDefault();
            var copyText = document.getElementById("urlVideo");

            /* Select the text field */
            copyText.select();

            /* Copy the text inside the text field */
            document.execCommand("copy");

            /* Alert the copied text */
            $.notify({
                message: 'Đã sao chép Url'
            },{
                type: 'meclip',
                delay: 2500,
                timer: 200,
                placement: {
                    from: 'bottom',
                    align: 'left'
                },
            });
        });
        /* Get the text field */

    })();
    (function () {
        $(document).on('click','.share-iframe',function (e) {
            e.preventDefault();
            $('.modal-share-social').modal('hide');
            $('.modal-share-iframe').modal('show');
            $('body.modal-open').css('overflow-y','hidden');
        });
        $('.modal-share-iframe').on('hidden.bs.modal', function (e) {
            $('body').css('overflow-y','auto');
        });
        $(document).on('click','.share-iframe',function (e) {
            /* Select the text field */
            $(this).select();

        });
        $(document).on('click','.iframe-coppy',function (e) {
            e.preventDefault();
            var copyText = $('.share-iframe');

            /* Select the text field */
            copyText.select();

            /* Copy the text inside the text field */
            document.execCommand("copy");

            /* Alert the copied text */
            $.notify({
                message: 'Đã sao chép liên kết vào bộ nhớ tạm'
            },{
                type: 'meclip',
                delay: 2000,
                timer: 200,
                placement: {
                    from: 'bottom',
                    align: 'left'
                },
            });
        });

    })();
    // Report
    (function () {
        $('form.video-report').ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {
                $('.modal-report-video').modal('hide');

                if (res.success){
                    $.notify({
                        message: res.success
                    },{
                        type: 'meclip',
                        delay: 2000,
                        timer: 100,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }else {
                    $.notify({
                        message: res.error
                    },{
                        type: 'meclip',
                        delay: 2000,
                        timer: 100,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
            },
            resetForm: true

        });
    })();

    // edit video
    (function () {
        $('form.video-edit :input,form.video-edit textarea').on('change',function () {
            var form = $(this).closest('form').addClass('changed');
        });
        $('form.video-edit').ajaxForm({
            success:function (res, status, xhr, $form) {
                console.log(res);
                $form.removeClass('changed');
                if (res){
                    $.notify({
                        message: res
                    },{
                        type: 'meclip',
                        delay: 2500,
                        timer: 100,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
            }
        });
        $(document).on('click','.delete-repeater',function () {
           $('form.video-edit').addClass('changed');
        });
        $(document).on('click','.btn-addSub',function () {
            $('form.video-edit').addClass('changed');
        });
        $(document).on('click','.video-edit .changed .btn-submit',function (e) {
            $(this).parents('form.video-edit').submit();
            $(this).parents('form.video-edit').removeClass('changed');
        });
    })();

    //
    (function () {
        let heightContent = $('.video__description .content .text-conent').height();
        let height =  $('.video__description .content').height();
        if(heightContent < height){
            $('.video__description .btn-expand').hide();
        }
    })();
})(jQuery);
