$(document).on('click','.upload__box',function (e) {
    if ($(this).hasClass('no-channel')){
        $.notify({
            message: 'Bạn chưa có kênh. Vui lòng tạo kênh trước khi upload.'
        },{
            type: 'meclip',
            delay: 3000,
            timer: 500,
        });
        return false;
    }
});
$(document).on('change','section.upload .upload--step-1 .video_file',function () {
    var $this = $(this);
    $this.parents('.box').find('.upload--step-1').hide();
    $this.parents('.box').find('.progress-bar').css('width','0%');
    $this.parents('.box').find('.upload--step-2').show();
    $this.parents('.upload__form').submit();
    $('.btn-addNewVideo').show();
});
$(document).on('click','.upload-video .submit[type="submit"]',function (e) {
    e.preventDefault();
    var $this = $(this);
    var $form = $this.closest('form');
    if ($this.hasClass('upload-done')){
        if ($this.hasClass('disable')){
            $.notify({
                title: '<strong>Hành động này không được!</strong>',
                message: 'Video này của bạn đã được khởi tạo/lên lịch thành công! Vui lòng tải lên video khác!'
            },{
                type: 'danger',
                delay: 3000,
                timer: 500,
            });
        }
        else {
            $form.submit();
        }
    }else {
        $.notify({
            title: '<strong>Có lỗi!</strong>',
            message: 'Video của bạn chưa được upload xong!Vui lòng đợi!'
        },{
            type: 'danger',
            delay: 3000,
            timer: 500,
        });
    }
});
(function ($) {
    $(document).on('click','.upload--cancel',function () {
        var formUpload = $(this).closest('form.upload__form');
        var number = $('.addNewVideo').attr('data-number');
        $('.addNewVideo').attr('data-number',parseInt(number)-1);
        formUpload.remove();
        var formContent = $(this).parents('.section--upload').html('Luồng upload này đã bị hủy');
    });
    $(document).on('submit','.upload__form',function (e) {
        e.preventDefault();
        var $this = $(this);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL+'/token-upload',{
            type: 'GET',
            dataType: 'json',
            success:function(res){
                console.log(res);
                if (res.success){
                    let token = res.success;
                    let formData = new FormData();
                    formData.append("file", $this.find('.video_file')[0].files[0]);
                    axios.post( res.api_upload,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'token': token
                            },
                            onUploadProgress: function(progressEvent) {
                                let percentCompletedCss = (progressEvent.loaded * 100) / progressEvent.total;
                                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                $this.parents('.box').find('.progress-bar').css('width',percentCompletedCss+'%');
                                $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + percentCompleted +' %</div>');
                                if (percentCompleted == 100){
                                    $this.parents('.box').find('.progress-bar').css('width',percentCompleted+'%');
                                    $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
                                }
                            }
                        }
                    ).then(function(res){
                        let item = res.data;
                        console.log(item);
                        if (item.code !== 200){
                            var number = $('.addNewVideo').attr('data-number');
                            $('.addNewVideo').attr('data-number',parseInt(number)-1);
                            $this.parents('.box').find('.upload-1 .section--header').empty();
                            $this.parents('.box').find('.upload-1 .section--header').append(item.msg);
                        }else {
                            $this.parents('.box').find('.upload-1 .progress .progress-bar').css('width','100%');
                            $this.parents('.box').find('.upload-1 .progress .progress-bar').html( 'Đã xử lý xong');
                            $this.parents('.box').find('.upload-1 .image-picker').empty();
                            $this.parents('.box').find('.upload-1 .yc-btn--sm').addClass('upload-done');
                            $this.parents('.box').find('.upload-1 input[name=originUrl]').val(item.data.originUrl);
                            $this.parents('.box').find('.upload-1 input[name=previewUrl]').val(item.data.previewUrl);
                            $this.parents('.box').find('.upload-1 input[name=thumbs1]').val(item.data.thumbs1[0]);
                            $this.parents('.box').find('.upload-1 input[name=thumbs2]').val(item.data.thumbs2[0]);
                            // $this.parents('.box').find('.upload-1 input[name=thumbs2_1]').val(item.data.thumbs2[0]);
                            // $this.parents('.box').find('.upload-1 input[name=thumbs2_2]').val(item.data.thumbs2[1]);
                            $this.parents('.box').find('.upload-1 input[name=fileName]').val(item.data.fileName);
                            $this.parents('.box').find('.upload-1 input[name=duration]').val(item.data.duration);
                            $this.parents('.box').find('.upload-1 input[name=uploadId]').val(item.data.uploadId);
                            $this.parents('.box').find('.upload-1 input[name=tokenUpload]').val(token);
                            $this.parents('.box').find('.upload-1 .image-picker').append('<option data-img-src="'+ item.data.thumbs1[0] +'" class="img-1" value="1" selected>img 1</option>');
                            $this.parents('.box').find('.upload-1 .image-picker').append('<option data-img-src="'+ item.data.thumbs2[0] +'" class="img-2" value="2">img 2</option>');
                            $this.parents('.box').find('.video__thumb .image-picker').imagepicker();
                        }
                    })
                }
            }
        });
        // $this.ajaxSubmit({
        //     beforeSubmit: function(formData, $form, options) {
        //         $form.find('.progress-bar').css('width','0%');
        //     },
        //     uploadProgress: function (event, position, total, percentComplete){
        //         $this.parents('.box').find('.progress-bar').css('width',percentComplete+'%');
        //         $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + percentComplete +' %</div>');
        //         if (percentComplete == 100){
        //             $this.parents('.box').find('.progress-bar').css('width',percentComplete+'%');
        //             $this.parents('.box').find('.progress-bar').html('<div class="progress-status">' + 'Đang xử lý' +'</div>');
        //         }
        //
        //     },
        //     success: function(res, statusText, xhr, $form) {
        //         console.log(res);
        //         if (res.error){
        //             var number = $('.addNewVideo').attr('data-number');
        //             $('.addNewVideo').attr('data-number',parseInt(number)-1);
        //             $form.parents('.box').find('.upload-1 .section--header').empty();
        //             $form.parents('.box').find('.upload-1 .section--header').append(res.error);
        //         }else {
        //             $form.parents('.box').find('.upload-1 .progress .progress-bar').css('width','100%');
        //             $form.parents('.box').find('.upload-1 .progress .progress-bar').html( 'Đã xử lý xong');
        //             $form.parents('.box').find('.upload-1 .image-picker').empty();
        //             $form.parents('.box').find('.upload-1 .yc-btn--sm').addClass('upload-done');
        //             $form.parents('.box').find('.upload-1 input[name=originUrl]').val(res.success.originUrl);
        //             $form.parents('.box').find('.upload-1 input[name=previewUrl]').val(res.success.previewUrl);
        //             $form.parents('.box').find('.upload-1 input[name=thumbs1_1]').val(res.success.thumbs1[0]);
        //             $form.parents('.box').find('.upload-1 input[name=thumbs1_2]').val(res.success.thumbs1[1]);
        //             $form.parents('.box').find('.upload-1 input[name=thumbs2_1]').val(res.success.thumbs2[0]);
        //             $form.parents('.box').find('.upload-1 input[name=thumbs2_2]').val(res.success.thumbs2[1]);
        //             $form.parents('.box').find('.upload-1 input[name=duration]').val(res.success.duration);
        //             $form.parents('.box').find('.upload-1 input[name=uploadId]').val(res.success.uploadId);
        //             $form.parents('.box').find('.upload-1 input[name=tokenUpload]').val(res.token);
        //             $form.parents('.box').find('.upload-1 .image-picker').append('<option data-img-src="'+ res.success.thumbs2[0] +'" class="img-1" value="1" selected>img 1</option>');
        //             $form.parents('.box').find('.upload-1 .image-picker').append('<option data-img-src="'+ res.success.thumbs2[1] +'" class="img-2" value="2">img 2</option>');
        //             $form.parents('.box').find('.video__thumb .image-picker').imagepicker();
        //         }
        //     },
        //     resetForm: true
        // });
    });
    $(document).on('submit','.upload-video',function (e) {
        e.preventDefault();
        var $this = $(this);
        $this.ajaxSubmit({
            beforeSubmit: function(formData, jqForm, options){
                var errors = fs.validateUpload(jqForm);
                // then we update the form to reflect the results
                jqForm.find('input,textarea,select').on('change',function(){
                    var errors = fs.validateUpload(jqForm);
                    if (errors) {
                        fs.showError(jqForm,errors);
                        return false;
                    }
                });
                if (errors) {
                    fs.showError(jqForm,errors);
                    return false;
                }

            },
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.error){
                    var number = $('.addNewVideo').attr('data-number');
                    $('.addNewVideo').attr('data-number',parseInt(number)-1);
                    $.notify({
                        title: '<strong>Có lỗi!</strong>',
                        message: res.error
                    },{
                        type: 'danger',
                        delay: 3000,
                        timer: 500,
                    });
                }
                else {
                    var number = $('.addNewVideo').attr('data-number');
                    $('.addNewVideo').attr('data-number',parseInt(number)-1);
                    $form.parents('.section--upload').html(res.html);
                    $.notify({
                        message: res.msg
                    },{
                        type: 'success',
                        delay: 3000,
                        timer: 500,
                    });
                }
            }
        });
    });

    $.datetimepicker.setLocale('vi');
    $('.puplic_date').datetimepicker({
        timepicker:false,
        format:'d/m/Y',
        minDate: '0',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('.public_time').datetimepicker({
        datepicker:false,
        format:'H:i'
    });
    $(document).on('click','.addNewVideo',function (e) {
        e.preventDefault();
        var $this = $(this);
        var number = $this.attr('data-number');
        if ( number <5 ) {
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/new-video-upload',{
                type: 'POST',
                dataType: 'json',
                data: {},
                success:function (data){
                    if (data){
                        var date = new Date();
                        var timestamp = date.getTime();
                        var $data = $(data).filter('.box-clone').addClass(''+timestamp+'');
                        $('section.upload .inner').append($data);
                        var select = $('.upload .'+timestamp);
                        select.find('input.video_file').trigger('click');
                        $this.attr('data-number',parseInt(number)+1);
                        var drEvent = select.find('.video-thumb-upload-clone').dropify({
                            messages: {
                                'default': 'Hình ảnh thu nhỏ tùy chỉnh',
                                'replace': 'Kéo thả hoặc chọn từ thiết bị để thay đổi',
                                'remove':  'Hủy',
                                'error':   'Xin lỗi có lỗi đã xảy ra'
                            },
                            error: {
                                'fileSize': 'Ảnh quá lớn ({{ value }} max).',
                                'minWidth': 'The image width is too small ({{ value }}}px).',
                                'maxWidth': 'The image width is too big ({{ value }}}px).',
                                'minHeight': 'The image height is too small ({{ value }}}px).',
                                'maxHeight': 'The image height is too big ({{ value }}px).',
                                'imageFormat': 'Định dạng không đúng ({{ value }}).',
                                'fileExtension': 'Không đúng dịnh dạng ({{ value }}).'
                            },
                        });
                        drEvent = drEvent.data('dropify');
                        drEvent.resetPreview();
                        drEvent.clearElement();
                        select.find('.video-hashtag-clone').tagsinput({
                            confirmKeys: [32, 13, 44]
                        });
                        select.find(".video__thumb .thumb-clone").imagepicker();
                    }
                }
            });

        }else {
            $.notify({
                message: 'Bạn đang có 5 luồng xử lý. Vui lòng xuất bản video đã xử lý xong để thêm video khác.'
            },{
                type: 'meclip',
                delay: 5000,
                timer: 500,
            });
        }
    });
    $(document).on('change','input[name=video_schedule]',function () {
        var $this = $(this);
        if($this.is(':checked')){
            $this.parents('form.upload-video').find('button.submit').text('Lên lịch');
        }else{
            $this.parents('form.upload-video').find('button.submit').text('Xuất bản');
        }
    });

})(jQuery);
