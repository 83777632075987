(function ($) {
    $.datetimepicker.setLocale('vi');
    $('#live_datepicker').datetimepicker({
        timepicker:false,
        format:'d/m/Y',
        minDate: '0',
        i18n:{
            vi:{
                months:[
                    'Th1','Th2','Th3','Th4',
                    'Th5','Th6','Th7','Th8',
                    'Th9','Th10','Th11','Th12'
                ],
                dayOfWeek:[
                    "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                    "Thứ 5", "Thứ 6", "Thứ 7",
                ]
            }
        }
    });
    $('#live_timepicker').datetimepicker({
        datepicker:false,
        format:'H:i'
    });
    $(document).on('click','.live-end',function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/live-end',{
            type: 'POST',
            dataType: 'json',
            data: {
              id : $(this).attr('data-id')
            },
            beforeSend:function(){

            },
            success:function (data){
                console.log(data);
                $(location).attr('href', data.url)
            }
        });
    });
    $(document).on('click','.live-cancel',function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/live-cancel-schedule',{
            type: 'POST',
            dataType: 'json',
            data: {
                id : $(this).attr('data-id')
            },
            beforeSend:function(){

            },
            success:function (data){
                console.log(data);
                $(location).attr('href', data.url)
            }
        });
    });
    $(document).on('click','.live-now',function (e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/live-now',{
            type: 'POST',
            dataType: 'json',
            data: {
                id : $(this).attr('data-id')
            },
            beforeSend:function(){

            },
            success:function (data){
                console.log(data);
                $(location).attr('href', data.url)
            }
        });
    });
    if ($('.chat_wrapper .message_box').length){
        $('.chat_wrapper .message_box').animate({
            scrollTop: $('.chat_wrapper .message_box')[0].scrollHeight}, 0);
        $(document).on("keypress", "input#message", function(e){
            if(e.which == 13){
                $('a#send-btn').trigger('click');
            }
        });
    }
})(jQuery);
