(function ($) {
    $.validator.setDefaults({
        success: "valid",
    });
    (function () {
        let form = $('form.contact-form');
        form.validate({
            rules:{
                name : {
                    required: true,
                    maxlength: 255
                },
                email:{
                    required: true,
                    email: true,
                    checkmail: true
                },
                description:{
                    required: true,
                },
            },
            messages: {
                name:
                    {
                        required:"Tên không được để trống",
                        maxlength: 'Không được quá 255 ký tự'
                    },
                description:{
                    required: "Chưa nhập nội dung"
                },
                email:{
                    required: "Bạn chưa điền email",
                    email : "Email không đúng định dạng",
                    checkmail : "Email không đúng định dạng"
                }
            },
        });
        form.ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {

                if (res.success){
                    $.notify({
                        message: res
                    },{
                        type: 'meclip',
                        delay: 2000,
                        timer: 100,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }else {
                    $.notify({
                        message: res
                    },{
                        type: 'meclip',
                        delay: 2000,
                        timer: 100,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
            },
            resetForm: true

        });
    })();
    // send support
    (function () {
        let form = $('form.sendSupport');
        form.validate({
            rules:{
                name : {
                    required: true,
                    email: true,
                    maxlength: 255
                },
                phone : {
                    required: true,
                },
                description:{
                    required: true,
                },
            },
            messages: {
                name:
                    {
                        email: "Không đúng định dạng email",
                        required:"Bạn chưa nhập Email",
                        maxlength: 'Không được quá 255 ký tự'
                    },
                description:{
                    required: "Chưa nhập nội dung"
                },
                phone:{
                    required: "Bạn chưa thêm số điện thoại"
                }
            },
        });
        form.ajaxForm({
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                if (res.success){
                    $('#modalSupport').modal('show');
                }else {
                    $.notify({
                        message: res.error
                    },{
                        type: 'meclip',
                        delay: 2000,
                        timer: 100,
                        placement: {
                            from: 'bottom',
                            align: 'left'
                        },
                    });
                }
            },
            resetForm: true
        })
    })();
})(jQuery);
