window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.axios = require('axios');
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    window.Swiper = require('swiper/dist/js/swiper');
    window.Buffer = window.Buffer || require('buffer').Buffer;
    window.fs = require("./functions");
    require('bootstrap');
    window.validate  = require("validate.js");
    window.Chart = require('chart.js');
    require('dropify/dist/js/dropify.min');
    require('bootstrap4-tagsinput-douglasanpa/tagsinput');
    require('jquery-validation/dist/jquery.validate.min');
    require('jquery-validation/dist/additional-methods.min');
    require('image-picker');
    require('jquery-form/dist/jquery.form.min');
    require('bootstrap-notify');
    require('jquery.repeater/jquery.repeater.min');
    require('x-editable/test/libs/poshytip/jquery.poshytip');
    require('x-editable/dist/jquery-editable/js/jquery-editable-poshytip');
    require('moment');
    require('jquery-datetimepicker');

} catch (e) {}
/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

