(function ($) {
    $.validator.setDefaults({
        success: "valid",
    });
    //Create channle form
    $('.channel__create').validate({
        rules:{
            channel_name : {
                required: true,
                maxlength: 25
            },
            channel_type:{
                required: true,
            },
            channel_description:{
                required: true,
            },
            channel_hashtag:{
                required: true,
            }
        },
        messages: {
            channel_name:
                {
                    required:"Bạn chưa nhập tên kênh",
                    maxlength: 'Tên kênh không được quá 25 ký tự'
                }
        },
    });
    //change pass
    $('.change-pass').validate({
        rules:{
            oldPassword : {
                required: true,
            },
            newPassword : {
                required: true,
                minlength: 6
            },
            confirm_newPassword : {
                equalTo: "#newPassword"
            }
        },
        messages: {
            oldPassword:
                {
                    required:"Bạn chưa nhập mật khẩu cũ",
                },
            newPassword:
                {
                    required:"Bạn chưa nhập mật khẩu mới",
                    minlength: "Mật khẩu phải chứa ít nhất 6 ký tự"
                },
            confirm_newPassword:
                {
                    equalTo: "Xác nhận mật khẩu mới không đúng"
                }
        },
    });
    // Sing up cooperation
    $('.cooperation__form').validate({
        rules:{
            name : {
                required: true,
                regex: "^[a-zA-Z'_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶ" +
                    "ẸẺẼỀẾỂưăạảấầẩẫậắằẳẵặẹẻẽềếểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợ" +
                    "ụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\\s]+$"
            },
            phone : {
                required: true,
                regex: "(0)+([0-9]{9})\\b"
            },
            address : "required",
            passport : {
                required: true,
                regex: "^[a-z0-9A-Z]+$"
            },
            where : "required",
            date : "required",
            tax_code : {
                required: true,
                regex: "^[a-z0-9A-Z]+$"
            },
        },
        messages:
            {
                passport: {
                    required: "Chưa nhập CMND/Hộ chiếu",
                    regex: "CMND/Hộ chiếu không được chứa ký tự đặc biệt"
                },
                name: {
                    required: "Chưa nhập họ tên",
                    regex: "Tên không được chứa ký tự đặc biệt"
                },
                phone:
                    {
                        required:"Bạn chưa nhập số điện thoại",
                        regex: "Số điện thoại của bạn không đúng định dạng"
                    },
                tax_code : {
                    required: "Vui lòng nhập mã số thuế",
                    regex: "Mã số thuế không đúng định dạng"
                },
            },
    });
    $('.channel-relate').validate({
        rules:{
            urlChannel : {
                required:true,
                url: true
            },
        }
    });
    $('form.video-report').validate({
        rules:{
            reportId:{ required:true }
        },
        messages:
            {
                reportId:
                    {
                        required:"Bạn chưa chọn nội dung để report"
                    }
            },
    });
    $('form.playlist__form').validate({
        rules:{
            playlistName : "required",
            playlistDescription : "required",
            playlist_channel : "required",
            playlistThumb : "required"
        },
        messages: {
            playlistThumb: {
                required:"Chưa có ảnh thumbnail",
            },
        },
    });
    $('.playlist-search-url').validate({
        rules:{
            urlVideo : {
                required:true,
                url: true
            },
        },
        messages: {
            urlVideo:
                {
                    required:"Chưa có URL",
                    url: 'Không phải là URL'
                }
                },
    });
    $('.live form').validate({
        ignore: "",
        rules:{
            liveThumb : {
                required: function (element) {
                    return ($('input[name=thumb_current]').val() == '')
                }
            },
            live_title : "required",
            live_des : "required",
            live_channel : "required",
            live_type: "required",
            pll_hashtag: "required"
        },
        messages: {
            thumb_current: {
                    required:"Chưa có ảnh thumbnail",
                },
            live_title: {
                required:"Chưa có tiêu đề",
            },
            live_des: {
                required:"Chưa có mô tả",
            },
            live_channel: {
                required:"Chưa chọn channel",
            },
            live_type: {
                required:"chưa chọn thể loại",
            },
            pll_hashtag: {
                required:"Chưa có hashtag",
            }
        },
    });
    $('.playlist-search').validate({
        errorElement : 'label',
        errorLabelContainer: '.error',
        rules:{
            s : {
                required:true,
            },
        },
        messages: {
            s:
                {
                    required:"Bạn chưa nhập nội dung tìm kiếm",

                }
        },
    });
    $('form.send-msg').validate({
        rules:{
            msg_content : {
                required:true,
            },
        },
        messages: {
            msg_content:
                {
                    required:"Bạn chưa nhập nội dung tin nhắn",
                }
        },
    });
    $('.info-personal').validate({
        rules:{
            email:{
                email: true,
                checkmail: true
            },
            displayName: {
                required:true,
                maxlength: 15
            }
        },
        messages: {
            email:{
                email:"Email chưa đúng định dạng",
                checkmail: "Email chưa đúng định dạng"
            },
            displayName:{
                required:"Bạn chưa có tên hiển thị",
                maxlength: "Tên hiển thị không được quá 15 ký tự"
            }
        },
    });
    $('.effective').validate({
        rules:{
            channel : {
                required:true,
            },
        },
        messages: {
            channel:
                {
                    required:"Bạn chưa chọn channel",
                },
        },
    });
})(jQuery);
