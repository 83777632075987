(function ($) {
    $('.playlist-search-loadmore').hide();
    $('.playlist-myvideo-loadmore').hide();

    //Search
    (function () {
        $('.playlist-search').ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {
                if (res.success){
                    $('.playlist-search-result').html('');
                    setTimeout(function () {
                        let btnMore =  $('.playlist-search-loadmore');
                        $('.playlist-search-result').html(res.success);
                        btnMore.attr('data-search',res.searchKey);
                        btnMore.attr('data-start',res.start);
                        if (!res.loadmore){
                            btnMore.removeClass('loading');
                        }else {
                            btnMore.addClass('loading');
                        }
                        $('.btn-pll-search').addClass('enable');
                    },200)
                }
            }
        });
        $('.playlist-search-url').ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {
                console.log(res);
                $('.playlist-url-result').html('');
                if (res.success){
                    $('.playlist-url-result').html(res.success);
                }
                else {
                    $('.playlist-url-result').html('<p>'+res.error+'</p>');
                }
                $('.btn-pll-url').addClass('enable');
            }
        });
        $(document).on('click','.playlist-search-loadmore',function (e) {
            e.preventDefault();
            var $this = $(this);
            var searchKey = $this.attr('data-search');
            var start = $this.attr('data-start');
            if (!searchKey)
                return null;
            else{
                if (!$this.hasClass('loading'))
                    return;

                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax(BASE_URL + '/playlist-search-video',{
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        s: searchKey,
                        start: start
                    },
                    beforeSend:function(){
                        $('.playlist-search-result').append('<div class="before-load text-center">Loading...</div>');
                    },
                    success:function (res){
                        setTimeout(function () {
                            $('.playlist-search-result .before-load').remove();
                            $('.playlist-search-result').append(res.success);
                            $this.attr('data-start',res.start);
                            if (!res.loadmore){
                                $this.removeClass('loading');
                            }else {
                                $this.addClass('loading');
                            }
                            let $scroll = true;
                            $('.playlist-search-result').scroll(function() {
                                if($scroll){
                                    if(Math.round($(this).scrollTop() + $(this).innerHeight() + 10) >= Math.round($(this)[0].scrollHeight)) {
                                        $('.playlist-search-loadmore').trigger('click');
                                        $scroll = false;
                                    }else {
                                        $scroll = true;
                                    }
                                }
                            });
                        },200)
                    }
                });
            }
        });
    })();

    (function () {
        let $scroll = true;
        $('.playlist-search-result').scroll(function() {
            if($scroll){
                if(Math.round($(this).scrollTop() + $(this).innerHeight() + 10) >= Math.round($(this)[0].scrollHeight)) {
                    $('.playlist-search-loadmore').trigger('click');
                    $scroll = false;
                }else {
                    $scroll = true;
                }
            }
        });
    })();
    (function () {
        let $scroll = true;
        $('.playlist-myvideo-result').scroll(function() {
            if($scroll){
                if(Math.round($(this).scrollTop() + $(this).innerHeight() + 10) >= Math.round($(this)[0].scrollHeight)) {
                    $('.playlist-myvideo-loadmore').trigger('click');
                    $scroll = false;
                }else {
                    $scroll = true;
                }
            }
        });
    })();
    (function () {
        $(document).on('click','.video-checkbox input[type=checkbox]',function () {
            if( $(this).is(':checked') ){
                $(this).closest('.video').addClass('checked');
            }else {
                $(this).closest('.video').removeClass('checked');
            }
        })
    })();
    //BTN add video
    (function () {
        $(document).on('click','.modal-playlist-add-video .btn-pll-search',function (e) {
            e.preventDefault();
            $('.modal-playlist-add-video .playlist-search-result .video.checked').each(function( index ) {
                $(this).clone().appendTo('.playlist__videos');
            });
            $('.playlist__edit-submit').trigger('click');
            $('.modal-playlist-add-video').modal('hide');
        });
        $(document).on('click','.modal-playlist-add-video .btn-pll-myVideo',function (e) {
            e.preventDefault();
            $('.modal-playlist-add-video .playlist-myvideo-result .video.checked').each(function( index ) {
                $(this).clone().appendTo('.playlist__videos');
            });
            $('.playlist__edit-submit').trigger('click');
            $('.modal-playlist-add-video').modal('hide');
        });
        $(document).on('click','.modal-playlist-add-video .btn-pll-url',function (e) {
            e.preventDefault();
            if ($(this).hasClass('enable')){
                $('.modal-playlist-add-video .playlist-url-result .video').each(function( index ) {
                    $(this).clone().appendTo('.playlist__videos');
                });
                $('.playlist__edit-submit').trigger('click');
                $('.modal-playlist-add-video').modal('hide');
            }
            else {

            }
        });
        $(document).on('click','.playlist-myvideo-loadmore',function (e) {
            e.preventDefault();
            var $this = $(this);
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/playlist-my-video',
                data:{
                    start : start,
                },
                beforeSend:function(){

                },
                success:function(res){
                    console.log(res);
                    let $scroll = true;
                    if (res.success){
                        $('.playlist-myvideo-result').append(res.success);
                        $this.attr('data-start',res.start)
                    }
                    if (!res.loadmore){
                        $this.remove();
                    }
                    $('.playlist-myvideo-result').scroll(function() {
                        if($scroll){
                            if(Math.round($(this).scrollTop() + $(this).innerHeight() + 10) >= Math.round($(this)[0].scrollHeight)) {
                                $('.playlist-myvideo-loadmore').trigger('click');
                                $scroll = false;
                            }else {
                                $scroll = true;
                            }
                        }
                    });
                }
            });
        });
    })();
    //removeVideo
    (function () {
        $(document).on('click','.playlist__videos .playlist__delete a',function(e){
            e.preventDefault();
            var $this = $(this);
            var id = $this.attr('data-id');
            var idPll = $this.attr('data-playlist');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/playlist/remove-video',
                data:{
                    id: id,
                    idPll : idPll,
                },
                beforeSend:function(){

                },
                success:function(res){
                    console.log(res);
                    if(res){
                        location.reload(true);
                    }
                }
            });
        })
    })();
    //Form creat PLL
    (function () {
        $(document).on('click','.playlist__addVideo-submit',function (e) {
            e.preventDefault();
            $('.playlist__videos .video.checked').each(function( index ) {
                let img = $(this).find('img').attr('src');
                if (index==0){
                    $('.playlist__form .playlist__thumb').val(img);
                }
            });
            $('.playlist__form').submit();
        })
    })();
    //Update PLL
    (function () {
        $(document).on('click','.playlist__edit-submit',function (e) {
            e.preventDefault();
            $('.pll_formEdit .playlist__videos .video').each(function( index ) {
                let img = $(this).find('img').attr('src');
                if (index==0){
                    $('.pll_formEdit .playlist__thumb').val(img);
                    return false;
                }
            });

            $('.pll_formEdit').submit();
        })
    })();
    (function () {
        $('.pll_change_thumb').ajaxForm({
            beforeSubmit: function(arr, $form, options) {
                // The array of form data takes the following form:
                // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
                // return false to cancel submit
            },
            success: function(res, statusText, xhr, $form) {
                console.log(res);
            }
        });
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $('.playlist__thumb').on('change',function () {
            $('.pll_change_thumb').submit();
        });
        $('.playlist-edit #pll__name').editable({
            mode: 'inline',
            type: 'text',
            onblur: 'submit',
            showbuttons: false,
            params: function(params) {
                //originally params contain pk, name and value
                params.pllId = $(this).attr('data-id');
                return params;
            },
            pk: 1,
            success: function(response, newValue) {
                console.log(response);
            },
            url: BASE_URL+'/playlist/update/content',
            validate: function(value) {
                if($.trim(value) == '') {
                    return 'Tên playlist là bắt buộc';
                }
            }
        });
        $('.playlist-edit #pll__des').editable({
            mode: 'inline',
            type: 'textarea',
            rows: 5,
            onblur: 'submit',
            cols: 30,
            showbuttons: false,
            params: function(params) {
                //originally params contain pk, name and value
                params.pllId = $(this).attr('data-id');
                return params;
            },
            pk: 1,
            success: function(response, newValue) {
                console.log(response);
            },
            url: BASE_URL+'/playlist/update/content',
            emptytext: 'Thêm mô tả'
        });
        $('.playlist-edit #pll__order').editable({
            mode: 'inline',
            type: 'select',
            onblur: 'submit',
            value: $(this).attr('data-value'),
            source: [
                {value: 0, text: 'Mới nhất'},
                {value: 1, text: 'Ngẫu nhiên'},
            ],
            showbuttons: false,
            params: function(params) {
                //originally params contain pk, name and value
                params.pllId = $(this).attr('data-id');
                return params;
            },
            pk: 1,
            success: function(response, newValue) {
                console.log(response);
            },
            url: BASE_URL+'/playlist/update/content',
        });
    })();
    //Load more video
    (function () {
        $(document).on('click','.pll_load-more-video',function (e) {
            e.preventDefault();
            var $this = $(this);
            var id = $this.attr('data-id');
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/playlist/load-more',
                data:{
                    id: id,
                    start : start,
                },
                beforeSend:function(){

                },
                success:function(res){
                    console.log(res);
                    if (res.success){
                        $('.pll_formEdit .playlist__videos').append(res.success);
                        $this.attr('data-start',res.start);
                    }
                    if(res.finish){
                        $this.remove();
                    }
                    var $scroll = true;
                    $(window).bind('scroll', function() {
                        var $select = $('.pll_formEdit .playlist__videos');
                        if (!$select.length)
                            return;
                        if ($scroll){
                            if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                $('.pll_load-more-video').trigger('click');
                                $scroll = false;
                            }
                            else {
                                $scroll = true;
                            }
                        }
                    });
                }
            });
        });
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.pll_formEdit .playlist__videos');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.pll_load-more-video').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();

    $(document).on('click', '#playlist-search .title__search-playlist', function(){
        let text = $(this).html();
        $('#playlist-search input.search').val(text);
        $('#playlist-search').submit();
    });
    $('#playlist-search input.search').on('input',function(){
        let $this = $(this);
        let searchKey = $this.val();
        if (searchKey == ''){
            $('#playlist-search .search_result').html('');
            return;
        }
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/playlist/search',{
            type: 'POST',
            dataType: 'json',
            data: {
                s: searchKey,
                start: 0
            },
            beforeSend:function(){
                $('#playlist-search .search_result').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>')
            },
            success:function (res){
                console.log(searchKey,res);
                if(res.success){
                    $('#playlist-search .search_result').html('<div class="inner m-auto" style="width: 96%;">'+ res.success +'</div>')
                }
            }
        });
    });
})(jQuery);
