(function ($) {
    (function () {
        $.datetimepicker.setLocale('vi');
        $('.info-personal .birthday').datetimepicker({
            timepicker:false,
            format:'d/m/Y',
            maxDate: '0',
            i18n:{
                vi:{
                    months:[
                        'Th1','Th2','Th3','Th4',
                        'Th5','Th6','Th7','Th8',
                        'Th9','Th10','Th11','Th12'
                    ],
                    dayOfWeek:[
                        "CN", "Thứ 2", "Thứ 3", "Thứ 4",
                        "Thứ 5", "Thứ 6", "Thứ 7",
                    ]
                }
            }
        });
    })();
    (function () {
        $('.personal .button-edit').on('click',function(e){
            e.preventDefault();
            $('.personal .info').hide();
            $('.personal .info-personal').show();
        })
    })();
    (function () {
        // $('.change-pass').ajaxForm({
        //     beforeSubmit: function(arr, $form, options) {
        //         // The array of form data takes the following form:
        //         // [ { name: 'username', value: 'jresig' }, { name: 'password', value: 'secret' } ]
        //         // return false to cancel submit
        //     },
        //     success: function(res, statusText, xhr, $form) {
        //         if (res.person_msg){
        //             $.notify({
        //                 message: res.person_msg
        //             },{
        //                 type: 'meclip',
        //                 delay: 3000,
        //                 timer: 100,
        //                 placement: {
        //                     from: 'bottom',
        //                     align: 'left'
        //                 },
        //             });
        //             $form.find('input[type=password]').val('');
        //         }
        //     },
        //     resetForm: true
        //
        // })
    })();
    (function () {
        $('.register-package').on('click',function (e) {
            e.preventDefault();
            let $this = $(this);
            let remark = $this.attr('data-remark');
            let cpdk  =  $this.attr('data-cpdk');
            let number  =  $this.attr('data-number');
            let price  =  $this.attr('data-price');
            var popup = $('.popup-register-package');
            popup.find('.modal-title').html(remark);
            popup.find('span.cpdk').html(cpdk);
            popup.find('span.target').html(number);
            popup.find('span.price').html(price);
            popup.modal('show');
        })
    })();
})(jQuery);
