(function ($) {
    (function () {
        $(document).on('click','.load-more-notification',function(){
            var $this = $(this);
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/management/load-more-notification',
                data:{
                    start : start,
                },
                beforeSend:function(){
                    $('.list-notification').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    if (res.success) {
                        setTimeout(function () {
                            var $select = $('.list-notification');
                            $('.list-notification .loading').remove();
                            $select.append(res.success);
                            if (parseInt(total) > (parseInt(res.start)+10)){
                                $this.attr('data-start',res.start)
                            }else {
                                $this.remove();
                            }
                            var $scroll = true;
                            if (!$select.length)
                                return;
                            $(window).bind('scroll', function() {
                                if ($scroll){
                                    if(Math.round($(window).scrollTop()+10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                        $('.load-more-notification').trigger('click');
                                        $scroll = false;
                                    }
                                    else {
                                        $scroll = true;
                                    }
                                }
                            });
                        },200)
                    }
                    else {
                        $('.list-notification .loading').remove();
                    }
                }
            });
        })
    })();
    (function () {
        var $scroll = true;
        var $select = $('.list-notification');
        if (!$select.length)
            return;
        $(window).bind('scroll', function() {
            if ($scroll){
                if(Math.round($(window).scrollTop()+10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.load-more-notification').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    //
    (function () {
        $('.notification.isLogin .icon').on('click',function (e) {
            e.preventDefault();
            var $select = $('.box-notifi-menu');
            $select.html('');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/management/load-more-notification',
                data:{
                    start : 0,
                    limit : 8
                },
                beforeSend:function(){
                    $select.html('');
                    $('.box-notifi-menu').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    console.log(res);
                    if (res.success) {
                        setTimeout(function () {
                            var $select = $('.box-notifi-menu');
                            $('.box-notifi-menu .loading').remove();
                            $select.html(res.success);
                            if (res.unread){
                                $('.notification span.unread').html(res.unread);
                            }
                            else {
                                $('.notification span.unread').html('');
                            }
                        },200)
                    }
                    else {
                        $('.box-notifi-menu .loading').remove();
                        $select.html('<p class="text-center">Không có thông báo nào</p>');
                    }
                }
            });
        })
    })();
    //Read
    (function () {
        $('.list-notification .list-group-item').on('click',function(e){
            e.preventDefault();
            var $this = $(this);
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/management/read-notification',
                data:{
                    id : $this.attr('data-id'),
                    targetId : $this.attr('data-target-id'),
                    type : $this.attr('data-type')
                },
                success:function(res){
                    console.log(res);
                    $(location).attr('href', res);
                }
            });
        });
        $(document).on('click','.header__button .notification .list-group-item',function(e){
            e.preventDefault();
            var $this = $(this);
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/management/read-notification',
                data:{
                    id : $this.attr('data-id'),
                    targetId : $this.attr('data-target-id'),
                    type : $this.attr('data-type')
                },
                success:function(res){
                    console.log(res);
                    $(location).attr('href', res);
                }
            });
        })
    })();
})(jQuery);
