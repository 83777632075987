(function ($) {
    // Video
    $(document).on('click','.manager-video .loadmore',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/manage-content/video',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.manager-video .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.manager-video .loading').remove();
                        $('.manager-video .row').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.manager-video');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.manager-video .loadmore').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });
                        fs.lazyload();
                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manager-video .loading').remove();
                    $this.remove();
                }


            }
        });


    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.manager-video');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.manager-video button.loadmore').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    // Playlist
    $(document).on('click','.manager-playlist .loadmore',function (e) {
        e.preventDefault();
        var $this = $(this);
        var start = $this.attr('data-start');

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/manage-content/playlist',{
            type: 'POST',
            dataType: 'json',
            data: {
                start: start
            },
            beforeSend:function(){
                $('.manager-playlist .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
            },
            success:function (res){
                if (res.success){
                    setTimeout(function () {
                        $('.manager-playlist .loading').remove();
                        $('.manager-playlist .row').append(res.success);
                        $this.attr('data-start',res.start);
                        var $scroll = true;
                        $(window).bind('scroll', function() {
                            var $select = $('.manager-playlist');
                            if (!$select.length)
                                return;
                            if ($scroll){
                                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                    $('.manager-playlist button.loadmore').trigger('click');
                                    $scroll = false;
                                }
                                else {
                                    $scroll = true;
                                }
                            }
                        });
                    },200);

                    if (res.finish){
                        $this.remove();
                    }
                }else {
                    $('.manager-playlist .loading').remove();
                    $this.remove();
                }


            }
        });


    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.manager-playlist');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.manager-playlist button.loadmore').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
})(jQuery);
