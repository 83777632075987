(function ($) {
    $(document).on('click','.search__result .loadmore',function (e) {
        e.preventDefault();
        var $this = $(this);
        var searchKey = $this.attr('data-search');
        var start = $this.attr('data-start');
        if (!searchKey)
            return null;
        else{
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/result',{
                type: 'POST',
                dataType: 'json',
                data: {
                    s: searchKey,
                    start: start
                },
                beforeSend:function(){
                    $('.list-result').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function (res){
                    if (res.success){
                        setTimeout(function () {
                            $('.list-result .loading').remove();
                            $('.list-result').append(res.success);
                            $this.attr('data-start',res.start);
                            var $scroll = true;
                            $(window).bind('scroll', function() {
                                var $select = $('.list-result');
                                if (!$select.length)
                                    return;
                                if ($scroll){
                                    if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                                        $('.page-search .loadmore').trigger('click');
                                        $scroll = false;
                                    }
                                    else {
                                        $scroll = true;
                                    }
                                }
                            });
                            fs.lazyload();
                            fs.preview();
                        },200);

                        if (res.finish){
                            $this.remove();
                        }
                    }else {
                        $('.list-result .loading').remove();
                        $this.remove();
                    }


                }
            });
        }

    });
    (function () {
        var $scroll = true;
        $(window).bind('scroll', function() {
            var $select = $('.list-result');
            if (!$select.length)
                return;
            if ($scroll){
                if(Math.round($(window).scrollTop() + 10) >= Math.round($select.offset().top + $select.outerHeight() - window.innerHeight)) {
                    $('.page-search button.loadmore').trigger('click');
                    $scroll = false;
                }
                else {
                    $scroll = true;
                }
            }
        });
    })();
    $(document).on('click',function(){
        $('.search_result').html('');
    });
    $(document).on('click', '#search-form .title__search-playlist', function(){
        let searchKey = $(this).html();
        $('#search-form input.search').val(searchKey);
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/search',{
            type: 'POST',
            dataType: 'json',
            data: {
                s: searchKey,
                start: 0
            },
            beforeSend:function(){
                $('#search-form .search_result').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>')
            },
            success:function (res){
                console.log(searchKey,res);
                if(res.success){
                    $('#search-form .search_result').html('<div class="inner">'+ res.success +'</div>')
                }
            }
        });
    });

    $(document).on('click', '.box-video .video-info .boxSearch_key', function(e){
        e.preventDefault();
        let key = $('#search-form input.search').val();
        let href = $(this).attr('data-href');
        console.log(key);
        console.log(href);
        if (key != ''){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/saveKeySearch',{
                type: 'GET',
                dataType: 'json',
                data: {
                    key: key
                },
                success:function (res){
                    console.log(res);
                    window.location.href = href;
                }
            });
        }else{
            window.location.href = href;
        }
    });

    $('#search-form input.search').on('click',function(){
        let $this = $(this);
        let searchKey = $this.val();
        $('#search-form .search_result').html('');
        if (searchKey == ''){
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/getSearch',{
                type: 'GET',
                dataType: 'json',
                data: {},
                beforeSend:function(){
                    $('#search-form .search_result').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>')
                },
                success:function (res){
                    console.log(res);
                    $('#search-form .search_result').html('');
                    if(res.success && (res.success).length > 0){
                        $('#search-form .search_result').html('<div class="inner">'+ res.success +'</div>')
                    }
                }
            });
        }else{
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/search',{
                type: 'POST',
                dataType: 'json',
                data: {
                    s: searchKey,
                    start: 0
                },
                beforeSend:function(){
                    $('#search-form .search_result').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>')
                },
                success:function (res){
                    console.log(searchKey,res);
                    if(res.success){
                        $('#search-form .search_result').html('<div class="inner">'+ res.success +'</div>')
                    }
                }
            });
        }
    });
    $('#search-form input.search').on('input',function(){
        var $this = $(this);
        var searchKey = $this.val();
        if (searchKey == ''){
            $('#search-form .search_result').html('');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax(BASE_URL + '/getSearch',{
                type: 'GET',
                dataType: 'json',
                data: {},
                beforeSend:function(){
                    $('#search-form .search_result').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>')
                },
                success:function (res){
                    console.log(res);
                    $('#search-form .search_result').html('');
                    if(res.success && (res.success).length > 0){
                        $('#search-form .search_result').html('<div class="inner">'+ res.success +'</div>')
                    }
                }
            });
            return;
        }
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });
        $.ajax(BASE_URL + '/search',{
            type: 'POST',
            dataType: 'json',
            data: {
                s: searchKey,
                start: 0
            },
            beforeSend:function(){
                $('#search-form .search_result').html('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>')
            },
            success:function (res){
                console.log(searchKey,res);
                if(res.success){
                    $('#search-form .search_result').html('<div class="inner">'+ res.success +'</div>')
                }
            }
        });
    })
})(jQuery);
