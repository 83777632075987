(function ($) {
    //watching
    (function () {
        $(document).on('click','.history-watching a.load-more',function (e) {
            e.preventDefault();
            var $this = $(this);
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/history-watching',
                data:{
                    start : start,
                },
                beforeSend:function(){
                    $('.history-watching .video .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    console.log(res);
                    if (res.success) {

                        setTimeout(function () {
                            $('.history-watching .video .row .loading').remove();
                            $('.history-watching .video .row').append(res.success);
                            fs.lazyload();
                            if (res.finish){
                                $this.remove();
                            }
                            $this.attr('data-start',res.start)
                        },200);
                    }
                    else {
                        $('.history-watching .video .row .loading').remove();
                        if (res.finish){
                            $this.remove();
                        }
                    }
                }
            });
        })
    })();
    //watched
    (function () {
        $(document).on('click','.history-watched a.load-more',function (e) {
            e.preventDefault();
            var $this = $(this);
            var start = $this.attr('data-start');
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                type:'POST',
                url: BASE_URL + '/history-watched',
                data:{
                    start : start,
                },
                beforeSend:function(){
                    $('.history-watched .video .row').append('<div class="col-12 loading text-center"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>');
                },
                success:function(res){
                    console.log(res);
                    if (res.success) {

                        setTimeout(function () {
                            $('.history-watched .video .row .loading').remove();
                            $('.history-watched .video .row').append(res.success);
                            fs.lazyload();
                            if (res.finish){
                                $this.remove();
                            }
                            $this.attr('data-start',res.start)
                        },200);
                    }
                    else {
                        $('.history-watched .video .row .loading').remove();
                        if (res.finish){
                            $this.remove();
                        }
                    }
                }
            });
        })
    })();
})(jQuery);
